:root {
  margin-bottom: 0;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 99vw;
  align-items: center;
  justify-content: center;
}

.iui-table-header-wrapper {
  --iui-color-background: #282c34;
  --iui-color-background-zebra: #282c34;
  --iui-color-background-hover: #393d46;
  --iui-color-background-backdrop: #282c34;
  --iui-color-background-backdrop-hover: #393d46;
  --iui-color-background-disabled: #282c34;
  --_iui-button-background-color: white;
  --_iui-button-text-color: white;
  --iui-color-text: white;
  --iui-color-icon: white;
  border-style: solid;
  border-color: white;
  border-width: 1px;
}


.iui-table-header .iui-table-cell:not(.iui-slot) .iui-table-filter-button:not([data-iui-active=true]) {
  opacity: 1 !important;
}


.iui-table-header .iui-table-cell {
  border-style: solid;
  border-color: white;
  border-width: 1px;
}

.iui-table-cell:first-of-type {
  border-style: solid;
  border-color: white;
  border-width: 1px;
}

.iui-table-body .iui-table-row .iui-table-cell {
  --iui-color-background: #282c34;
  --iui-color-background-zebra: #282c34;
  --iui-color-background-hover: #282c34;
  --iui-color-background-backdrop: #282c34;
  --iui-color-background-backdrop-hover: #282c34;
  --iui-color-background-disabled: #282c34;
  border-style: solid;
  border-color: white;
  border-width: 0.01px;
  overflow-x: scroll;
}


.iui-table-body .iui-table-row[aria-selected=true] .iui-table-cell {
  background-color: #393d46 !important;
}


body {
  margin-top: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#legend {
  font-family: Arial, sans-serif;
  color: rgba(255, 255, 255, 0.8);
  border: 2px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 1px grey;
  padding: 5px;
  width: max-content;
  margin: auto;
}

#legend p {
  text-align: center;
}

.legend-table {
  width: 100%;
}

.legend-row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  justify-items: start;
}

.legend-item {
  align-items: center;
  display: flex;
}

.color-box {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.not-modeled {
  background-color: orange;
}

.weak-emergence {
  background-color: #00C310;
}

.strong-emergence {
  background-color: #39FF14;
}

.positive-emergence {
  background-color: #4600D1;
}

.negative-emergence {
  background-color: #da14ff;
}


.TableNIST {
  width: 99%;
}

.TableNIST .iui-table {
  width: 100%;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .legend-row {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  .legend-item {
    font-size: 10px;
  }

  .color-box {
    width: 12px;
    height: 12px;
  }

  #legend p {
    font-size: 12px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .TableNIST .iui-table {
    font-size: 10px;
  }

  .iui-table .iui-table-header {
    font-size: 10px;
  }
  
}

@media (min-width: 601px) and (max-width: 1000px) {
  .legend-row {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  .legend-item {
    font-size: 13px;
  }

  #legend p {
    font-size: 15px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .TableNIST .iui-table {
    font-size: 12px;
  }

  .iui-table .iui-table-header {
    font-size: 10px;
  }

}

@media (min-width: 1001px) {
  .legend-row {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
  }

  .legend-item {
    font-size: 13px;
  }

  #legend p {
    font-size: 15px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .TableNIST .iui-table {
    font-size: 12px;
  }

  .iui-table .iui-table-header {
    font-size: 12px;
  }

}