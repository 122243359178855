  .App {
    text-align: center;
    width: auto;
    height: fit-content;
    margin-bottom: 0;
    padding-bottom: 0;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
  }

  .Text-AF {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
    text-align: right;
    z-index: 7;
  }

  .twitter {
    margin-bottom: 0;
  }

  .App-header {
    width: 99%;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-relative {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .App-item {
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 0;
    position: absolute;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
  }

  .App-logo {
    height: auto;
    z-index: 0;
    -webkit-animation-duration: 15s;
    animation-duration: 15s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
  }

  #mychart .sunburst-viz {
    z-index: 1;
    animation: fadeIn 20s;
    position: -webkit-sticky !important;
    position: sticky !important;
    display: flex;
    flex-direction: column;
    flex: 100%;
    align-items: flex-start;

  }

  #mychart .sunburst-viz .tooltip {
    white-space: normal;
    text-align: left;
    font-style: normal;
    opacity: 0.91;
    background-color: black;
    min-width: 200px;
    max-width: 300px;
    height: auto;
  }

  #mychart .sunburst-viz .tooltip .tooltip-title {
    text-align: center;

  }

  .Text-header {
    overflow-x: hidden;
    pointer-events: none;
    user-select: none;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    padding-left: auto;
    padding-right: auto;
    text-align: left;
  }

  .fire {
    font-size: calc(40px + 1vw);
    font-weight: 400;
  }

  .TableNIST {
    width: 99%;
    background-color: #282c34;
    color: white;
    text-align: left;
    overflow-x: scroll;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    touch-action: pan-x;
    touch-action: pinch-zoom;
  }

  .TableNIST .iui-table {
    background-color: #282c34;
    overflow-x: scroll;
  }

  @media only screen and (max-width: 480px) {


    .App-item {
      top: 20%;
      width: 100%;
    }

    .App-logo {
      max-width: 480px;
      width: 100%;
      height: auto;
    }

    .Text-header {
      max-width: 360px;
      position: relative;
      line-height: 1.15;
      font-size: 0.9em;
    }

    .fire {
      font-size: calc(40px + 1vw);
    }

    .TableNIST {
      max-width: 480px;
      line-height: 1.2;
      font-size: calc(12px + 0.1vw);
    }

    .Text-AF {
      max-width: 480px;
      font-size: 0.8em;
      line-height: 1.5;
      margin-right: 20px;
    }

    .twitter {
      width: 16px;
      height: auto;
    }
  }

  @media only screen and (min-width: 481px) {

    .App-item {
      top: 50%;
      width: 100%;
    }

    .App-logo {
      min-width: 481px;
      width: 100%;
      height: auto;
    }

    .Text-header {
      min-width: 481px;
      max-width: 680px;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 20px;
      font-size: calc(0.9em - 0.1vw);
      line-height: 1.2;
    }

    .fire {
      font-size: calc(50px + 1vw);
    }

    .TableNIST {
      min-width: 481px;
      line-height: 1.2;
      font-size: calc(13px + 0.1vw);
    }

    .Text-AF {
      min-width: 481px;
      font-size: calc(3px + 1vw);
      line-height: 1.9;
    }

    .twitter {
      width: 14px;
      height: auto;
    }

  }

  @media only screen and (min-width: 680px) {

    .App-item {
      top: 50%;
      width: 100%;
    }

    .App-logo {
      min-width: 481px;
      width: 100%;
      height: auto;
    }

    .Text-header {
      min-width: 680px;
      max-width: 700px;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 20px;
      font-size: calc(0.7em - 0.1vw);
      line-height: 1.2;
    }

    .fire {
      font-size: calc(50px + 1vw);
    }

    .TableNIST {
      min-width: 481px;
      line-height: 1.2;
      font-size: calc(13px + 0.1vw);
    }

    .Text-AF {
      min-width: 481px;
      font-size: calc(2px + 1vw);
      line-height: 1.9;
    }

    .twitter {
      width: 16px;
      height: auto;
    }

  }

  @media only screen and (min-width: 890px) {

    .App-item {
      top: 15%;
      width: 100%;
    }

    .App-logo {
      min-width: 481px;
      width: 65%;
      height: auto;
    }

    .Text-header {
      min-width: 680px;
      max-width: 700px;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 20px;
      font-size: calc(0.7em - 0.1vw);
      line-height: 1.2;
    }

    .fire {
      font-size: calc(50px + 1vw);
    }

    .TableNIST {
      min-width: 481px;
      line-height: 1.2;
      font-size: calc(13px + 0.1vw);
    }

    .Text-AF {
      min-width: 481px;
      font-size: calc(2px + 1vw);
      line-height: 1.9;
    }

    .twitter {
      width: 16px;
      height: auto;
    }

  }

  @media only screen and (min-width: 1119px) {

    .App-item {
      top: 50%;
      width: 100%;
    }

    .App-logo {
      min-width: 481px;
      width: 50%;
      height: auto;
    }

    .Text-header {

      max-width: 700px;
      padding-top: 1%;
      line-height: 1.4;
      font-size: 0.65em;
    }

    .fire {
      font-size: calc(60px + 1vw);
    }

    .TableNIST {
      min-width: 1119px;
      line-height: 1.1;
      font-size: calc(15px + 0.1vw);
    }

    .Text-AF {
      line-height: 1.5;
      font-size: calc(4px + 0.8vw);
      min-width: 1119px;
    }

    .twitter {
      width: 18px;
      height: auto;
    }

  }

  @media only screen and (min-width: 1819px) {

    .App-item {
      top: 50%;
      width: 100%;
    }

    .App-logo {
      min-width: 481px;
      width: 50%;
      height: auto;
    }

    .Text-header {

      max-width: 700px;
      padding-top: 1%;
      line-height: 1.4;
      font-size: 0.65em;
    }

    .fire {
      font-size: calc(60px + 1vw);
    }

    .TableNIST {
      min-width: 1119px;
      line-height: 1.1;
      font-size: calc(15px + 0.1vw);
    }

    .Text-AF {
      line-height: 1.5;
      font-size: calc(0px + 0.8vw);
      min-width: 1119px;
    }

    .twitter {
      width: 18px;
      height: auto;
    }

  }

  .fire {
    text-align: center;
    margin: auto;
    animation: lights 5s 750ms linear infinite;
  }


  @-webkit-keyframes fadeOutUp {
    0% {
      opacity: 0.95;
      -webkit-transform: translateY(0);
    }

    100% {
      opacity: 0;
      -webkit-transform: translateY(0);
    }
  }

  @keyframes fadeOutUp {
    0% {
      opacity: 0.91;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0.1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes lights {
    0% {
      color: hsl(230, 40%, 80%);
      text-shadow:
        0 0 1em hsla(320, 100%, 50%, 0.2),
        0 0 0.125em hsla(320, 100%, 60%, 0.3),
        -1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
        1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
    }

    30% {
      color: hsl(230, 80%, 90%);
      text-shadow:
        0 0 1em hsla(320, 100%, 50%, 0.5),
        0 0 0.125em hsla(320, 100%, 60%, 0.5),
        -0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
        0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
    }

    40% {
      color: hsl(230, 100%, 95%);
      text-shadow:
        0 0 1em hsla(320, 100%, 50%, 0.5),
        0 0 0.125em hsla(320, 100%, 90%, 0.5),
        -0.25em -0.125em 0.125em hsla(40, 100%, 60%, 0.2),
        0.25em 0.125em 0.125em hsla(200, 100%, 60%, 0.4);
    }

    70% {
      color: hsl(230, 80%, 90%);
      text-shadow:
        0 0 1em hsla(320, 100%, 50%, 0.5),
        0 0 0.125em hsla(320, 100%, 60%, 0.5),
        0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
        -0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
    }

    100% {
      color: hsl(230, 40%, 80%);
      text-shadow:
        0 0 1em hsla(320, 100%, 50%, 0.2),
        0 0 0.125em hsla(320, 100%, 60%, 0.3),
        1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
        -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
    }

  }

